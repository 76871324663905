import { create } from 'apisauce';

const baseURL = "https://task-api.nexat.de"

const backendAPI = create({
    baseURL: baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})


export default backendAPI