import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/home/HomeView.vue'),
            meta: {
                isAuthenticated: true,
            },
        },
        {
            path: '/map',
            name: 'map',
            component: () => import('@/views/MapView.vue'),
            children: [
                {
                    path: 'fields',
                    name: 'fields',
                    component: () => import('@/views/FieldsView.vue'),
                },
                {
                    name: 'field',
                    path: 'field/:id',
                    component: () => import('@/views/FieldView.vue'),
                },
            ],
            meta: {
                isAuthenticated: true,
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                isAuthenticated: false,
            },
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/AboutView.vue'),
            meta: {
                isAuthenticated: true,
            },
        },
    ],
})
const sleep = (ms) => new Promise((res) => setTimeout(res, ms))

router.beforeEach(async (to, from, next) => {
    if (to.meta.isAuthenticated) {

        while (router.app.config.globalProperties.$keycloak.createLoginUrl === null) {
            await sleep(100)
        }

        if (router.app.config.globalProperties.$keycloak.authenticated) {
            return next()
        } else {
            // Get the actual url of the app, it's needed for Keycloak
            const loginUrl = router.app.config.globalProperties.$keycloak.createLoginUrl({
                redirectUri: window.location.origin + to.fullPath,
            })
            window.location.assign(loginUrl)
            next()
        }
    } else {
        return next()
    }
})
export default router
