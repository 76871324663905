<template>
    <div class="ml-auto mr-2">
        <Button class="nav-btn w-auto" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu">{{this.profile.firstName + ' ' + this.profile.lastName}}</Button>
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
    </div>

</template>

<script>
export default {
    data() {
        return {
            items: [
                {label: 'Profile', icon: 'pi pi-user', command: () => {
                    this.$keycloak.accountManagement()
                }},
                {label: 'Logout', icon: 'pi pi-power-off', command: () => {
                    this.$keycloak.logoutFn()
                }}
            ],
            profile:{}
        }
    },
    async mounted(){
        this.profile =await this.$keycloak.loadUserProfile()
    },
    setup () {

        return {}
    },
    methods: {
        async toggle(e) {
            this.$refs.menu.toggle(e)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>