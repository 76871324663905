// import './assets/main.scss'

/* import Pinia */
import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'

/* import Keycloak */
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'

/* import App */
import App from './App.vue'

/* import Router */
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import Responsive service */
import { Vue3Mq } from 'vue3-mq'

/* import PrimeVue setup */
import { primeSetup } from './prime'

/* import specific icons */
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import backendAPI from './scripts/httpCommon'
import { isoCtrlAPI } from './scripts/isoCtrl'
import { taskAPI, taskAPIDownload } from './scripts/task'
/* add icons to the library */
library.add(faTwitter, faUserSecret, fas)

const app = createApp(App)
const pinia = createPinia()

/**
 * FontAwesomeIcons
 */
app.component('fa', FontAwesomeIcon)

/**
 * Pinia setup
 */
pinia.use(({ store }) => {
    store.router = markRaw(router)
})
app.use(pinia)

/**
 * Keycloak setup
 */

/**
 * Responsive setup
 */
app.use(Vue3Mq, {
    preset: 'bootstrap5',
})

/**
 * PrimeVue Components setup
 */
// import('./prime').then((setup) => {
//     setup.primeSetup(app)
// })
primeSetup(app)

export let keycloak = null
app.use(VueKeycloakJs, {
    init: {
        //Check the iframe for login
        checkLoginIframe:true,
        // Use 'login-required' to always require authentication
        // If using 'login-required', there is no need for the router guards in router.js
        // As the HomeView also requires authenticated user, we need 'login-required' here
        onLoad: 'login-required',
        silentCheckSsoRedirectUri: window.location.origin + window.location.path,
        // Specifies whether the silent check-sso should fallback to "non-silent" check-sso when 3rd party cookies are blocked by the browser.
        silentCheckSsoFallback: true

    },
    config: {
        url: 'https://auth.nexat.de/',
        realm: 'taskdb',
        clientId: import.meta.env.VITE_KC_CLIENT_ID,
        'public-client': true,
        'verify-token-audience': false,
    },
    onReady(kk) {

        backendAPI.axiosInstance.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${kk.token}`
            return config
        })
        isoCtrlAPI.axiosInstance.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${kk.token}`
            return config
        })
        taskAPI.axiosInstance.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${kk.token}`
            return config
        })
        taskAPIDownload.axiosInstance.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${kk.token}`
            return config
        })
        keycloak = kk
        app.mount('#app')
    },
})

/** Router must always be last otherwise stuff like the ToastService from primevue will not load properly */

app.use(router)

router.app = app

export default app
