import { create } from 'apisauce'
import backendAPI from './httpCommon'

export const taskAPI = create({
    baseURL: import.meta.env.VITE_APP_TASK_PROXY,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With',
    },
    timeout: 10000,
})
export const taskAPIDownload = create({
    baseURL: import.meta.env.VITE_APP_TASK_PROXY,
    responseType: 'blob',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With',
    },
    timeout: 10000,
})
/**
 * WhoAmI
 */
export async function getWhoami() {
    let res = backendAPI.get('/gviz/whoami').then((rs) => (rs.ok ? rs.data : false))
    return res
}
//#region Read
/**
 * Get Fields
 */
export const getFields = async (cIds) => {
    const res = await taskAPI.post('/gviz/getfields', {
        customerIDs: cIds
    }).then((rs) => (rs.ok ? rs.data : rs.ok))

    if(res === null || res === undefined)
        return []
    return res
}

/**
 * Get Field Details
 */
export const getField = async (id) => {
    const res = await taskAPI.get(`/gviz/getfield/${id}`).then((rs) => (rs.ok ? rs.data : rs.ok))
    return res
}

/**
 * Get Tasks
 */
export const getTasks = async (fieldId) => {
    const res = await taskAPI.get(`/gviz/gettasks/${fieldId}`).then((rs) => (rs.ok ? rs.data : rs.ok))
    return res
}

/**
 * Get Task Details
 */
export const getTask = async (id) => {
    const res = await taskAPI.get(`/gviz/gettask/${id}`).then((rs) => (rs.ok ? rs.data : rs.ok))
    return res
}
/**
 * Get Task ISO Data
 */
export const downloadISODataFromTask = async (id) => {
    const res = await taskAPIDownload.get(`/gviz/downloadtask/${id}`).then((rs) => rs)
    return res
}
/**
 * Get Plans
 */
export const getPlans = async (taskId) => {
    const res = await taskAPI.get(`/gviz/getplans/${taskId}`).then((rs) => (rs.ok ? rs.data : rs.ok))
    return res
}

/**
 * Get Plan Details
 */
export const getPlan = async (id) => {
    const res = await taskAPI.get(`/gviz/getplan/${id}`).then((rs) => (rs.ok ? rs.data : rs.ok))
    return res
}
//#endregion

//#region Create
/**
 * Create Field
 */
export const createField = async (data) => {
    const res = await taskAPI.post('/gviz/savefield', data).then((rs) => (rs.ok ? rs.data : rs.ok))
    return res
}

/**
 * Create Task
 */
export const createTask = async (data) => {
    const res = await taskAPI.post('/gviz/savetask', data).then((rs) => (rs))
    return res
}

/**
 * Create Plan
 */
export const createPlan = async (taskId, data) => {
    const res = await taskAPI.post(`/gviz/saveplan/${taskId}`, data).then((rs) => (rs.ok ? rs.data : rs.ok))
    return res
}

//#endregion

//#region Update

/**
 * Update Field
 */
export const updateField = async (data) => {
    const res = await taskAPI.put(`/gviz/updatefield`, data).then((rs) => (rs.ok ? rs.data : rs.ok))
    return res
}

/**
 * Update Task
 */
export const updateTask = async (data) => {
    const res = await taskAPI.put(`/gviz/updatetask`, data).then((rs) => (rs.ok))
    return res
}

/**
 * Update Plan
 */
export const updatePlan = async (data) => {
    const res = await taskAPI.put(`/gviz/updateplan`, data).then((rs) => (rs.ok ? rs.data : rs.ok))
    return res
}

//#endregion
