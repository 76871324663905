import { create } from 'apisauce'


export const isoCtrlAPI = create({
    baseURL: import.meta.env.VITE_APP_TASK_PROXY,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With',
    },
    timeout: 10000,
})

export const uploadISODataToTask = async (taskId, formData) => {
    isoCtrlAPI.setHeader('Content-Type', 'multipart/form-data')
    const res = await isoCtrlAPI.post(`/isobus/importtask/${taskId}`, formData).then((rs) => rs)

    return res
}
