<template>
    <nav class="nav">
        <Button class="nav-btn" link><router-link class="nav-link" to="/">Home</router-link></Button>
        <Button class="nav-btn" link><router-link class="nav-link" to="/map/fields">Map</router-link></Button>
        <!-- Hidden as it is incomplete -->
        <!-- <Button class="nav-btn" link><a href="https://wiki.nexat.de/en/component/component_fieldmanager" target="_blank" class="nav-link">Wiki</a></Button> -->
        <AccountButton />
    </nav>
    <div class="mc">
        <router-view> </router-view>
    </div>
</template>

<script>
import AccountButton from './components/account/AccountButton.vue'
export default {
    name: 'App',
    setup() {

    },

    computed: {
        checksso() {
            return this.$keycloak.authenticated
        },
    },
    methods: {},
    components: {
        AccountButton
    }
}
</script>

<style lang="scss">
@import url('./assets/main.scss');
.nav-btn {
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
